import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar";
import Strip from "../Strip";
import AllContacts from "../AllContacts";

export default function Shareholders() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        <Strip>{t("shareholders.invitation")}</Strip>
        <div className="mt-16">
          <Strip>{t("shareholders.notes")}</Strip>
        </div>
      </div>
      <AllContacts />
    </>
  );
}
