import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiLoading, mdiCheck } from "@mdi/js";

export default function ContactForm() {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const sendForm = async () => {
    setLoading(true);
    const bodyJSON = { name, email, phone, message };
    const res = await fetch("https://contactform.vinefin.cz", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyJSON),
    });
    setLoading(false);
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    if (res.status !== 200) {
      return alert("Error while sending email.");
    }
    setSuccess(true);
  };
  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <input
          className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-vinefin focus:ring-2 fade-transition"
          placeholder={t("contact.name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-vinefin focus:ring-2 fade-transition"
          placeholder={t("contact.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-vinefin focus:ring-2 fade-transition"
          placeholder={t("contact.phone")}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <textarea
          className="col-span-3 h-36 px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-vinefin focus:ring-2 fade-transition"
          placeholder={t("contact.message")}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className="mt-4 flex flex-row justify-end items-center gap-4">
        {loading ? (
          <Icon
            className="text-vinefin animate-spin"
            path={mdiLoading}
            size={1.5}
          />
        ) : null}
        {success ? (
          <Icon className="text-vinefin" path={mdiCheck} size={1.5} />
        ) : null}
        <button
          className="px-6 py-2 rounded-xl bg-vinefin text-white hover:bg-white hover:text-vinefin disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
          disabled={loading || success}
          onClick={sendForm}
        >
          {t("contact.send")}
        </button>
      </div>
    </>
  );
}
