import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import OneLanguage from "./OneLanguage";
import flagCs from "./img/flagCs.png";
import flagEn from "./img/flagEn.png";
import flagEs from "./img/flagEs.png";
import flagRu from "./img/flagRu.png";

export default function LanguageDropdown() {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { i18n } = useTranslation();
  const [languageCode, setLanguageCode] = useState(i18n.language.split("-")[0]);
  useEffect(() => {
    setLanguageCode(i18n.language.split("-")[0]);
  }, [i18n.language]);
  const flags = {
    cs: flagCs,
    en: flagEn,
    es: flagEs,
    ru: flagRu,
  };
  const toggleOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false); // Close the dropdown if clicked outside
      }
    };

    // Attach the event listener when the dropdown is open
    if (open) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    // Clean up by removing the event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [open]);
  return (
    <div ref={dropdownRef}>
      <div
        className="inline-flex w-18 justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer fade-transition"
        onClick={toggleOpen}
      >
        <div className="flex items-center">
          <img className="w-6 mr-2" src={flags[languageCode]} alt="" />
        </div>
        <Icon path={mdiChevronDown} size={1} />
      </div>
      {open ? (
        <div className="absolute right-4 z-10 mt-2 w-56 origin-top-left md:origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none fade-transition">
          <div className="py-1">
            <OneLanguage
              locale="cs"
              name="Čeština"
              flag={flagCs}
              setOpen={setOpen}
            />
            <OneLanguage
              locale="en"
              name="English"
              flag={flagEn}
              setOpen={setOpen}
            />
            <OneLanguage
              locale="es"
              name="Español"
              flag={flagEs}
              setOpen={setOpen}
            />
            <OneLanguage
              locale="ru"
              name="Русский"
              flag={flagRu}
              setOpen={setOpen}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
