import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar";
import Strip from "../Strip";
import AllCompanies from "../AllCompanies";
import AllContacts from "../AllContacts";

export default function Companies() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        <Strip>{t("home.structure")}</Strip>
        <AllCompanies />
      </div>
      <AllContacts />
    </>
  );
}
