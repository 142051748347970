import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import csTranslations from "./translations/cs.json";
import enTranslations from "./translations/en.json";
import esTranslations from "./translations/es.json";
import ruTranslations from "./translations/ru.json";
import Home from "./pages/Home";
import Companies from "./pages/Companies";
import Projects from "./pages/Projects";
import Investment from "./pages/Investment";
import Actualities from "./pages/Actualities";
import Shareholders from "./pages/Shareholders";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/companies",
    element: <Companies />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/investment",
    element: <Investment />,
  },
  {
    path: "/actualities",
    element: <Actualities />,
  },
  {
    path: "/shareholders",
    element: <Shareholders />,
  },
]);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) //Detect broser language
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      cs: {
        translation: csTranslations,
      },
      en: {
        translation: enTranslations,
      },
      es: {
        translation: esTranslations,
      },
      ru: {
        translation: ruTranslations,
      },
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
