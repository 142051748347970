import React from "react";

export default function StripDouble({ title, children }) {
  return (
    <div className="bg-vinefin-light mt-16 py-4 px-4 rounded-full">
      <h1 className="text-3xl md:text-4xl font-bold text-center">{title}</h1>
      <p className="text-xl md:text-2xl text-center">{children}</p>
    </div>
  );
}
