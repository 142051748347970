import React from "react";
import Navbar from "../Navbar";
import Actuality from "../Actuality";

export default function Actualities() {
  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <Actuality
            title="Tomáš Jandík: Otevírá se okno pro investice do nemovitostí. Než ECB začne snižovat sazby"
            content="Kdo má aktuálně kapitál, dokáže zajistit lépe dlouhodobé příležitosti. Až klesnou sazby ECB, hodnota nemovitostí opět poroste, říká Tomáš Jandík, místopředseda představenstva REICO Investiční společnost České spořitelny."
            img="https://www.newstream.cz/uploads/article/660x464_fit/Jandik.png"
            url="https://www.newstream.cz/reality/tomas-jandik-otevira-se-okno-pro-investice-do-nemovitosti-nez-zacne-snizovat-sazby-ecb"
          />
          <Actuality
            title="Už žádných 6 % na spořicích účtech. Banky srážejí úroky až o polovinu!"
            content="České banky už potichu, ale nikoliv pomalu začínají snižovat své úrokové sazby na spořicích účtech."
            img="https://d8-a.sdn.cz/d_8/c_imgrestricted_QO_N/dbOJt/banka-stat-penize-uroky-dane.png?fl=cro,0,224,1024,576%7Cres,1024,,1%7Cwebp,75"
            url="https://medium.seznam.cz/clanek/petr-horak-uz-zadnych-6-na-sporicich-uctech-banky-srazeji-uroky-az-o-polovinu-38050?utm_campaign=abtest242_shorts_varBB&utm_medium=z-boxiku&utm_source=www.seznam.cz"
          />
        </div>
      </div>
    </>
  );
}
