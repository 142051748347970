import React from "react";

export default function OneCompany({ name, content, contentbold, icon }) {
  return (
    <div>
      <div className="flex flex-row items-center justify-between gap-6 mt-8">
        <h3 className="text-2xl font-black">{name}</h3>
        {icon}
      </div>
      <p className="text-xl mt-4">
        {content}
        <strong> {contentbold}</strong>
      </p>
    </div>
  );
}
