import React from "react";
import { useTranslation } from "react-i18next";
import ContactForm from "./ContactForm";
import OneContact from "./OneContact";
import Icon from "@mdi/react";
import {
  mdiPhone,
  mdiEmail,
  mdiMapMarker,
  mdiWeb,
  mdiStorefront,
  mdiCashMultiple,
} from "@mdi/js";

export default function AllContacts() {
  const { t } = useTranslation();
  const IconTemplate = (path) => (
    <Icon className="text-vinefin" path={path} size={1} />
  );
  return (
    <div className="bg-vinefin-light px-12 py-8">
      <h1 className="text-3xl font-black mb-8">{t("contact.title")}</h1>
      <ContactForm />
      <div className="mt-8 grid md:grid-cols-3 md:grid-rows-2 gap-4">
        <OneContact
          name={t("contact.phone")}
          content={["+420 775 072 622", "+420 730 180 084"]}
          icon={IconTemplate(mdiPhone)}
        />
        <OneContact
          name={t("contact.address")}
          content={["ČSA 27/1, 785 01 Šternberk, Česká republika"]}
          icon={IconTemplate(mdiMapMarker)}
        />
        <OneContact
          name={t("contact.bank")}
          content={["Česká spořitelna a.s., 5595351389/0800"]}
          icon={IconTemplate(mdiCashMultiple)}
        />
        <OneContact
          name={t("contact.email")}
          content={["info@vinefin.cz"]}
          icon={IconTemplate(mdiEmail)}
        />
        <OneContact
          name={t("contact.id")}
          content={["07903936"]}
          icon={IconTemplate(mdiStorefront)}
        />
        <OneContact
          name={t("contact.website")}
          content={["vinefin.cz"]}
          icon={IconTemplate(mdiWeb)}
        />
      </div>
    </div>
  );
}
