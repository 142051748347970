import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar";
import Strip from "../Strip";
import IncomeTable from "../IncomeTable";
import AllContacts from "../AllContacts";

export default function Investment() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        <Strip>{t("investment.title")}</Strip>
        <p className="text-xl mt-12">{t("investment.paragraph1")}</p>
        <p className="text-xl mt-4">{t("investment.paragraph2")}</p>
        <IncomeTable />
      </div>
      <AllContacts />
    </>
  );
}
