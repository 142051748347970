import React from "react";

export default function Actuality({ title, content, img, url }) {
  return (
    <div
      className="bg-gray-200 hover:brightness-90 rounded-xl cursor-pointer fade-transition"
      onClick={() => window.open(url)}
    >
      <img className="rounded-t-xl" src={img} alt="" />
      <div className="px-4 pt-2 pb-4">
        <h1 className="text-xl font-black">{title}</h1>
        <p className="text-sm mt-2">{content}</p>
      </div>
    </div>
  );
}
