import React, { useState } from "react";

export default function ImageWithSkeleton({ src, double }) {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {!loaded ? (
        <div
          className={`${
            double ? "col-span-4" : ""
          } bg-gray-200 dark:bg-gray-500 animate-pulse aspect-square rounded-2xl`}
        />
      ) : null}
      <img
        className={`${!loaded ? "hidden" : ""} ${
          double ? "col-span-4" : ""
        } rounded-2xl`}
        src={src}
        onLoad={() => setLoaded(true)}
        alt=""
      />
    </>
  );
}
