import React from "react";
import i18next from "i18next";
export default function OneLanguage({ name, locale, flag, setOpen }) {
  const changeLanguage = (language) => {
    i18next.changeLanguage(language);
    setOpen(false);
  };
  return (
    <div
      className="text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm cursor-pointer fade-transition"
      onClick={() => changeLanguage(locale)}
    >
      <div className="flex items-center">
        <img className="w-6 mr-2" src={flag} alt="" />
        <p>{name}</p>
      </div>
    </div>
  );
}
