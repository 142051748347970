import React from "react";
import { useTranslation } from "react-i18next";

export default function IncomeTable() {
  const { t } = useTranslation();
  const makeArray = () => {
    return Array.from({ length: 10 }, (_, index) => index + 1);
  };
  return (
    <div class="sm:w-3/4 py-2 align-middle mx-auto mt-12">
      <div class="overflow-scroll no-scrollbar shadow ring-1 ring-black ring-opacity-5 rounded-lg">
        <table class="w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
              >
                {t("investment.table.col1")}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {t("investment.table.col2")}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {t("investment.table.col3")}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {t("investment.table.col4")}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-black text-gray-900"></td>
              <td class="whitespace-nowrap px-3 py-4 text-sm font-black text-gray-900">
                0,5%
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm font-black text-gray-900">
                6%
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm font-black text-gray-900">
                18%
              </td>
            </tr>
            {makeArray().map((el) => (
              <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-black text-gray-900">
                  {100000 * el} Kč
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {100000 * el * 0.005} Kč
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {100000 * el * 0.06} Kč
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {100000 * el * 0.18} Kč
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
