import React from "react";
import Strip from "./Strip";

export default function Article({ name, content, img, isReversed }) {
  if (isReversed) {
    return (
      <div className="flex flex-col xl:flex-row items-center gap-16">
        <div className="hidden xl:block w-1/2">
          <img className="mx-auto max-h-96" src={img} alt="" />
        </div>
        <div className="xl:w-1/2">
          <Strip>{name}</Strip>
          <p className="text-xl mt-4">{content}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col xl:flex-row items-center gap-16">
      <div className="xl:w-1/2">
        <Strip>{name}</Strip>
        <p className="text-xl mt-4">{content}</p>
      </div>
      <div className="hidden xl:block w-1/2">
        <img className="mx-auto max-h-96" src={img} alt="" />
      </div>
    </div>
  );
}
