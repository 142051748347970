import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";
import logoVinefin from "./img/logoVinefin.png";
import NavbarLink from "./NavbarLink";
import LanguageDropdown from "./LanguageDropdown";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  const { t } = useTranslation();
  return (
    <nav className="bg-vinefin-light shadow fixed top-0 w-full py-4 px-4">
      <div className="flex justify-between items-center">
        <div onClick={toggleMenu} className="cursor-pointer lg:hidden">
          <Icon path={mdiMenu} size={1.3} />
        </div>
        <div className="flex-shrink-0">
          <img className="w-44 md:w-60" src={logoVinefin} alt="vinefin.cz" />
        </div>
        <div className="hidden lg:flex lg:space-x-4 self-center">
          <NavbarLink to="/">{t("navbar.aboutus")}</NavbarLink>
          <NavbarLink to="/companies">{t("navbar.companies")}</NavbarLink>
          <NavbarLink to="/projects">{t("navbar.projects")}</NavbarLink>
          <NavbarLink to="/investment">{t("navbar.investment")}</NavbarLink>
          <NavbarLink to="/actualities">{t("navbar.actualities")}</NavbarLink>
          <NavbarLink to="/shareholders">{t("navbar.shareholders")}</NavbarLink>
          <NavbarLink
            onClick={() =>
              window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: "smooth",
              })
            }
            to="#"
          >
            {t("navbar.contact")}
          </NavbarLink>
        </div>
        <LanguageDropdown />
      </div>
      {open ? (
        <div className="pt-4 flex flex-col gap-2 lg:hidden">
          <NavbarLink to="/">{t("navbar.aboutus")}</NavbarLink>
          <NavbarLink to="/companies">{t("navbar.companies")}</NavbarLink>
          <NavbarLink to="/projects">{t("navbar.projects")}</NavbarLink>
          <NavbarLink to="/investment">{t("navbar.investment")}</NavbarLink>
          <NavbarLink to="/actualities">{t("navbar.actualities")}</NavbarLink>
          <NavbarLink to="/shareholders">{t("navbar.shareholders")}</NavbarLink>
          <NavbarLink
            onClick={() => {
              window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: "smooth",
              });
              setOpen(false);
            }}
            to="#"
          >
            {t("navbar.contact")}
          </NavbarLink>
        </div>
      ) : null}
    </nav>
  );
}
