import React from "react";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";
import AllContacts from "../AllContacts";
import Article from "../Article";

export default function Home() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        <div className="flex flex-col gap-16">
          <Article
            name={t("home.title")}
            content={t("home.aboutus")}
            img="./art-houses.png"
          />
          <Article
            name={t("home.title2")}
            content={t("home.inworks")}
            img="./art-house.png"
            isReversed={true}
          />
          <Article
            name={t("home.title3")}
            content={t("home.ourgoals")}
            img="./art-wind-turbine.png"
          />
        </div>
      </div>
      <AllContacts />
    </>
  );
}
