import React from "react";
import Navbar from "../Navbar";
import AllProjects from "../AllProjects";
import AllContacts from "../AllContacts";

export default function Projects() {
  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        <AllProjects />
      </div>
      <AllContacts />
    </>
  );
}
