import React from "react";
import StripDouble from "./StripDouble";
import ImageWithSkeleton from "./ImageWithSkeleton";

export default function OneProject({
  name,
  content,
  photoFolder,
  photoAmount,
}) {
  const makeArray = () => {
    return Array.from({ length: photoAmount }, (_, index) => index + 1);
  };
  return (
    <div>
      <StripDouble title={name}>{content}</StripDouble>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mt-4">
        {photoAmount > 1 ? (
          makeArray().map((el) => (
            <ImageWithSkeleton src={`/${photoFolder}/${el}.jpg`} />
          ))
        ) : (
          <ImageWithSkeleton double={true} src={`/${photoFolder}/1.jpg`} />
        )}
      </div>
    </div>
  );
}
