import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function NavbarLink({ children, to, onClick }) {
  const location = useLocation();
  const isActive = location.pathname === to;
  const activeClasses =
    "inline-flex items-center border-b-2 border-vinefin px-1 pt-1 text-md font-black text-gray-900 fade-transition";
  const notActiveClasses =
    "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-md font-black text-gray-500 hover:border-gray-300 hover:text-gray-700 fade-transition";
  return (
    <Link
      onClick={
        onClick
          ? onClick
          : () =>
              window.scrollTo({
                top: 0,
                behavior: "instant",
              })
      }
      className={isActive ? activeClasses : notActiveClasses}
      to={to}
    >
      {children}
    </Link>
  );
}
