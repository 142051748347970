import React from "react";
import { useTranslation } from "react-i18next";
import OneCompany from "./OneCompany";
import Icon from "@mdi/react";
import {
  mdiCashMultiple,
  mdiOfficeBuildingOutline,
  mdiLandPlots,
  mdiAccountTie,
  mdiHomeOutline,
} from "@mdi/js";

export default function AllCompanies() {
  const { t } = useTranslation();
  const IconTemplate = (path) => (
    <Icon className="text-vinefin mr-8" path={path} size={2.5} />
  );
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <OneCompany
        name="VINEFIN group&nbsp;a.s."
        content={t("home.companies.group")}
        icon={IconTemplate(mdiCashMultiple)}
      />
      <OneCompany
        name="VINEFIN Obchodní&nbsp;s.r.o."
        content={t("home.companies.obchodni")}
        icon={IconTemplate(mdiOfficeBuildingOutline)}
      />
      <OneCompany
        name="VINEFIN Obchodní&nbsp;2&nbsp;s.r.o."
        content={t("home.companies.obchodni2")}
        contentbold={t("home.companies.obchodni2bold")}
        icon={IconTemplate(mdiLandPlots)}
      />
      <OneCompany
        name="VINEFIN Hypoteční&nbsp;s.r.o."
        content={t("home.companies.hypotecni")}
        icon={IconTemplate(mdiAccountTie)}
      />
      <OneCompany
        name="VINEFIN Senior&nbsp;s.r.o."
        content={t("home.companies.senior")}
        icon={IconTemplate(mdiHomeOutline)}
      />
    </div>
  );
}
